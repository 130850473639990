<template>
  <router-view
    v-slot="{ Component }"
    menu-title="Admin"
  >
    <component :is="Component">
      <template v-slot:filter-bar-slot>
        <slot name="filter-bar-slot" />
      </template>
    </component>
  </router-view>
</template>
